import * as React from "react";
const TrophyIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29 8.75H25.75V6.5C25.75 6.30109 25.671 6.11032 25.5303 5.96967C25.3897 5.82902 25.1989 5.75 25 5.75H7C6.80109 5.75 6.61032 5.82902 6.46967 5.96967C6.32902 6.11032 6.25 6.30109 6.25 6.5V8.75H3C2.53587 8.75 2.09075 8.93437 1.76256 9.26256C1.43437 9.59075 1.25 10.0359 1.25 10.5V12.5C1.25 13.7598 1.75044 14.968 2.64124 15.8588C3.53204 16.7496 4.74022 17.25 6 17.25H6.6425C7.20096 19.1476 8.32339 20.8304 9.86076 22.0751C11.3981 23.3198 13.2777 24.0674 15.25 24.2188V27.75H12C11.8011 27.75 11.6103 27.829 11.4697 27.9697C11.329 28.1103 11.25 28.3011 11.25 28.5C11.25 28.6989 11.329 28.8897 11.4697 29.0303C11.6103 29.171 11.8011 29.25 12 29.25H20C20.1989 29.25 20.3897 29.171 20.5303 29.0303C20.671 28.8897 20.75 28.6989 20.75 28.5C20.75 28.3011 20.671 28.1103 20.5303 27.9697C20.3897 27.829 20.1989 27.75 20 27.75H16.75V24.2188C20.805 23.9037 24.1787 21.0562 25.3275 17.25H26C27.2598 17.25 28.468 16.7496 29.3588 15.8588C30.2496 14.968 30.75 13.7598 30.75 12.5V10.5C30.75 10.0359 30.5656 9.59075 30.2374 9.26256C29.9092 8.93437 29.4641 8.75 29 8.75ZM6 15.75C5.13805 15.75 4.3114 15.4076 3.7019 14.7981C3.09241 14.1886 2.75 13.362 2.75 12.5V10.5C2.75 10.4337 2.77634 10.3701 2.82322 10.3232C2.87011 10.2763 2.9337 10.25 3 10.25H6.25V14.5C6.25126 14.9179 6.27839 15.3354 6.33125 15.75H6ZM24.25 14.3875C24.25 18.965 20.5775 22.7162 16.0625 22.75C14.9738 22.7582 13.8943 22.5509 12.8862 22.14C11.878 21.7291 10.9611 21.1227 10.1884 20.3558C9.41571 19.5889 8.80242 18.6767 8.38392 17.6716C7.96541 16.6666 7.74997 15.5887 7.75 14.5V7.25H24.25V14.3875ZM29.25 12.5C29.25 13.362 28.9076 14.1886 28.2981 14.7981C27.6886 15.4076 26.862 15.75 26 15.75H25.6537C25.7174 15.2986 25.7496 14.8434 25.75 14.3875V10.25H29C29.0663 10.25 29.1299 10.2763 29.1768 10.3232C29.2237 10.3701 29.25 10.4337 29.25 10.5V12.5Z"
      fill="#6F6F6F"
      stroke="white"
      stroke-width="0.2"
    />
  </svg>
);
export default TrophyIcon;
