import Quill from "quill";
import CustomInput from "../../CustomInput/CustomInput";
import RadioCircleUI from "../../form/RadioCircleUI";
import Checkbox from "../../Checkbox/Checkbox";
import {
  ColumnContainer,
  Container,
  CurrectAnsText,
  CurrectAnsTitle,
  OptionNumberText,
  OptionRowContainer,
  RadioContainer,
  RowButtonContainer,
  RowContainer,
  RowQuestionContainer,
  SavedQuestion,
  SavedQuestionRowView,
  SavedQuestionView,
  Select,
  Title,
} from "./Question.styles";
import PencilLine from "../../../assets/svg/PencilLine";
import Trash from "../../../assets/svg/Trash";
import Button from "../../button/button";
import { colors } from "../../../utils/theme";
import { Controller, useForm } from "react-hook-form";
import PlusCircleIcon from "../../../assets/svg/PlusCircleIcon";
import MinusCircle from "../../../assets/svg/MinusCircle";
import ChevronDown from "../../../assets/svg/ChevronDown";
import React, { useEffect, useRef } from "react";
import QuillEditor from "./QuillEditor";
import QuillEditorViewOnly from "../../QuillEditor/QuillEditorViewOnly";

const Question = ({
  count = 0,
  totalCount = 0,
  question,
  onChangeQuestionType,
  addAndRemoveOptions,
  onSave,
  addNewQuestion,
  onEditQuestion,
  onDeleteQuestion,
  isLoading,
}) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      question: question?.question,
    },
  });

  const enableAddQuestion = question.saved && question.editing;

  const [editorContent, setEditorContent] = React.useState("");

  const hideAddQuestionButton =
    question?.hideAddQuestion && question?.hideAddQuestion;

  const fields = watch();

  const changeEditorText = (value) => {
    setValue("editorContent", value);
  };

  useEffect(() => {
    if (question?.choices?.length) {
      setValue("answer", question?.answer);
      question?.choices?.forEach((choice) => {
        setValue(`option ${choice?.id}`, choice?.option);
      });
    }
  }, [question?.choices]);

  useEffect(() => {
    if (question?.type === "single-answer" && question?.editing) {
      setValue("question", question?.question);
    }

    return () => {
    };
  }, [question]);


  if (!question?.editing) {
    return (
      <SavedQuestionRowView>
        <SavedQuestionView>
          <OptionNumberText>
            Question {count}/{totalCount}
          </OptionNumberText>
          <div className="flex justify-between flex-1">
            {question?.type === "multiple-choice" ? (
              <SavedQuestion>{question.question}</SavedQuestion>
            ) : (
              <QuillEditorViewOnly value={question?.question} />
            )}
            <ChevronDown className={"w-5 h-5"} fill={"#2e2e2e"} />
          </div>
        </SavedQuestionView>
        <button onClick={onEditQuestion}>
          <PencilLine />
        </button>
        <button onClick={onDeleteQuestion}>
          <Trash />
        </button>
      </SavedQuestionRowView>
    );
  }
  return (
    <Container>
      <RowQuestionContainer>
        <Title>
          Question {count}/{totalCount}
        </Title>
      </RowQuestionContainer>
      <ColumnContainer>
        {question?.type === "multiple-choice" && (
          <CustomInput
            name="question"
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
            placeholder="Enter your question here"
            isTextArea={true}
            resize={false}
            className="border-0"
          />
        )}
        {question?.choices?.map((c, index) => (
          <OptionRowContainer>
            <OptionNumberText>{index + 1}.</OptionNumberText>
            <CustomInput
              key={c.id}
              placeholder={`Option ${index + 1}`}
              name={`option ${c.id}`}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />
            <button
              onClick={() =>
                addAndRemoveOptions({ type: "add", choiceIndex: index })
              }
            >
              <PlusCircleIcon
                fill={colors.Green}
                className="w-[24px] h-[24px]"
              />
            </button>
            <button
              onClick={() =>
                addAndRemoveOptions({ type: "remove", choiceIndex: index })
              }
            >
              <MinusCircle />
            </button>
          </OptionRowContainer>
        ))}
        {question?.type !== "single-answer" ? (
          <>
            <CurrectAnsTitle>Choose correct answer</CurrectAnsTitle>
            <RowContainer>
              {question?.choices?.map((c, index) => (
                <RadioContainer
                  key={index + 1}
                  onClick={() => setValue("answer", index + 1)}
                >
                  {question?.type === "multiple-choice" ? (
                    <RadioCircleUI checked={fields.answer - 1 === index} />
                  ) : (
                    question?.type === "check-box" && (
                      <Checkbox
                        className="w-4 h-4"
                        value={question.answer === index}
                        fill={colors.PrimaryBlack}
                      />
                    )
                  )}
                  <CurrectAnsText>Option {index + 1}</CurrectAnsText>
                </RadioContainer>
              ))}
            </RowContainer>
          </>
        ) : (
          <div style={{ position: "relative" }}>
            <Controller
              name="question"
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
              render={({ field: { value, onChange } }) => (
                <QuillEditor value={value} onChange={onChange} />
              )}
            />
          </div>
        )}
      </ColumnContainer>

      <RowButtonContainer>
        <Button
          color={colors.PrimaryBlack}
          variant="outline"
          disabled={!isValid}
          style={{ borderRadius: 4, maxHeight: 36 }}
          onClick={handleSubmit(onSave)}
        >
          {isLoading ? (
            <div className="flex gap-1 items-center	justify-center">
              {/* <LoadingIcon className={"h-4 w-4 animate-spin "} fill={"black"} /> */}
              <span className="font-montserrat font-normal text-sm">Save</span>
            </div>
          ) : (
            <span className="font-montserrat font-normal text-sm">Save</span>
          )}
        </Button>
      </RowButtonContainer>
    </Container>
  );
};

export default Question;
