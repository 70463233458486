import React from "react";
import GlobeIcon from "../../../assets/svg/GlobeIcon";
import Divider from "../../../components/Divider/Divider";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { experiences } from "../dummyData";

const SkillItem = ({ skill, value, index, skills }) => (
  <>
    <div className="flex justify-between items-center">
      <span className="flex-1 font-montserrat text-primary font-normal text-xs">
        {skill}
      </span>
      <ProgressBar value={value} total={10} />
    </div>
    {index < skills.length - 1 && <Divider className="bg-[#DDDDDD]" />}
  </>
);

const ExperienceSection = ({ title, company, location }) => (
  <div className="flex gap-3 w-[30%]">
    <GlobeIcon />
    <div className="flex flex-col gap-2">
      <span className="font-montserrat text-light-black font-bold text-sm">
        {title}
      </span>
      <span className="font-montserrat text-light-black font-semibold text-sm">
        {company}
      </span>
      <span className="font-montserrat text-light-black font-medium text-sm">
        {location}
      </span>
    </div>
  </div>
);

const DescriptionBlock = ({ title, description }) => (
  <div className="mb-10 flex flex-col">
    <span className="font-montserrat text-primary font-semibold text-sm mb-2">
      {title}
    </span>
    <span className="font-montserrat text-primary font-normal text-xs text-justify leading-5">
      {description}
    </span>
  </div>
);

const ExperienceBlock = ({
  duration,
  company,
  location,
  role,
  description,
  skills,
}) => {
  return (
    <div className="flex">
      <ExperienceSection
        title={duration}
        company={company}
        location={location}
      />
      <div className="flex flex-col w-3/5">
        <DescriptionBlock title={role} description={description} />
        <div className="pr-16 flex flex-col">
          <div className="flex mb-5">
            <span className="font-montserrat text-primary font-semibold text-sm flex-1">
              Skills Used
            </span>
            <span className="font-montserrat text-primary font-semibold flex-1 text-sm">
              Experience Level
            </span>
          </div>
          <div className="flex flex-col gap-4">
            {skills.map(({ skill, value }, index) => (
              <SkillItem
                key={skill}
                skill={skill}
                value={value}
                index={index}
                skills={skills}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Experience = () => {
  return (
    <div className="h-full flex flex-1 flex-col px-24 py-6 pt-12 overflow-y-scroll no-scrollbar">
      <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
        Work Experience
      </span>
      <div className="flex flex-col gap-11">
        {experiences.map((exp, index) => (
          <React.Fragment key={index}>
            <ExperienceBlock {...exp} />
            {index < experiences.length - 1 && (
              <Divider className="bg-[#DDDDDD]" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Experience;
