import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import regexList from "../../utils/regexList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserData,
  tokenValidateSuccess,
} from "../../store/actions/auth.action";
import { login } from "../../endpoints/auth.service";
import { useSnackbar } from "notistack";
import TickOutline from "../../assets/svg/TickOutline";

const LoginPage = () => {
  const { token, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const [selectedRole, setSelectedRole] = useState("employer");

  const handleRoleChange = () => {
    setSelectedRole((prev) => {
      if (prev === "employer") {
        return "candidate";
      } else {
        return "employer";
      }
    });
  };
  const handleLogin = (data) => {
    login(data)
      .then((res) => {
        // dispatch(setUserData(res?.data));
        dispatch(tokenValidateSuccess(res?.data));
      })
      .catch((err) => {
        navigate(`/email-verification`, {
          state: {
            email: data?.email,
          },
        });
        enqueueSnackbar(err, { variant: "error" });
      });
  };

  useEffect(() => {
    if (token && user && user._id) {
      navigate("/dashboard", { replace: true });
    }
  }, [token, user]);

  return (
    <div className="h-full flex">
      {/* Form Container */}
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg py-10 px-20 bg-white relative w-max h-max m-auto">
        <button
          onClick={handleRoleChange}
          className="font-medium underline text-secondary mb-2 ml-auto absolute -top-8 right-1"
        >
          {selectedRole === "employer"
            ? "Are you looking for job?"
            : "Are you an employer?"}
        </button>
        <label className="font-semibold text-3xl mb-2">
          {selectedRole === "employer" ? "Employer" : ""} Login
        </label>
        <label className="text-light-black font-medium">
          Welcome back! Please login to access your account
        </label>
        <form
          className="flex flex-col w-full pt-10"
          onSubmit={handleSubmit(handleLogin)}
        >
          <CustomInput
            label={"Email"}
            name="email"
            placeholder={"Enter your Email"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            control={control}
            errors={errors}
          />
          <div className="h-6" />
          <CustomInput
            label={"Password"}
            placeholder={"Enter your Password"}
            name="password"
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.password,
                message: "Please enter a valid password.",
              },
            }}
            control={control}
            errors={errors}
          />

          {/* Checkbox container  */}
          <div className="flex justify-between items-center w-full mt-3 text-light-black">
            <div className="flex items-center gap-2">
              <div
                onClick={() => setKeepMeSignedIn((prev) => !prev)}
                className="border-2 border-[#6F6F6F] h-4 w-4 flex items-center justify-center cursor-pointer"
              >
                {keepMeSignedIn ? (
                  <TickOutline className="w-1.5 h-1.5" fill="#6F6F6F" />
                ) : null}
              </div>
              <span className="font-montserrat font-medium text-light-black text-sm">
                Keep me signed in
              </span>
            </div>
            <span className="font-montserrat font-medium text-light-black text-xs cursor-pointer">
              Forgot password?
            </span>
          </div>

          <button
            type="submit"
            className="bg-secondary rounded-full mt-10 py-3"
          >
            <span className="text-white font-medium">Login</span>
          </button>
          <span className="mx-auto text-sm mt-1 font-medium text-light-black">
            Don’t have an account?{" "}
            <span
              onClick={() => navigate("/register")}
              className="text-secondary cursor-pointer"
            >
              Register
            </span>
          </span>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
