import CustomInput from "../../../components/CustomInput/CustomInput"
import Switch from "../../../components/Switch/Switch"

const Step2 = ({
    control,
    errors
}) => {
    return <div className="w-[80%] self-center">
        <div className="flex flex-col gap-7 w-1/2">
            <CustomInput
                label={'Job Title'}
                placeholder={'Jr. IT Analyst'}
                name="jobTitle"
                rules={{
                    required: "This field is required.",
                }}
                control={control}
                errors={errors}
            />
            <CustomInput
                label={'Job Location'}
                placeholder={'Enter Job Location'}
                name="jobLocation"
                rules={{
                    required: "This field is required.",
                }}
                control={control}
                errors={errors}
            />
            <CustomInput
                label={'Industry Type'}
                placeholder={'Enter Industry Type'}
                name="industryType"
                rules={{
                    required: "This field is required.",
                }}
                control={control}
                errors={errors}
            />
            <CustomInput
                label={'Salary Range'}
                placeholder={'Enter salary range'}
                name="salaryRange"
                rules={{
                    required: "This field is required.",
                }}
                control={control}
                errors={errors}
            />
            <div>
                <Switch />
                <label className="text-xs text-light-grey">Hide salary from your ad</label>
            </div>
            <CustomInput
                label={'Job Description'}
                placeholder={'Who this course is for'}
                isTextArea
                name="jobDescription"
                rules={{
                    required: "This field is required.",
                }}
                control={control}
                errors={errors}
            />
        </div>
    </div>
}

export default Step2