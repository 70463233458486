const Switch = () => {
    return <div class="flex items-center">
        <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" class="sr-only peer" />
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-secondary dark:bg-gray-700"></div>
            <span class="absolute w-5 h-5 bg-white rounded-full transition-all peer-checked:translate-x-[1.38rem] left-0.5 top-0.5 peer-checked:left-auto"></span>
        </label>
    </div>
}

export default Switch