import { Link, useNavigate } from "react-router-dom";
import ChevronDown from "../../../assets/svg/ChevronDown";
import PlusIcon from "../../../assets/svg/PlusIcon";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { getCompanyList } from "../../../endpoints/company.service";
import { useEffect, useState } from "react";

const ItemCard = ({ data }) => {
  return (
    <Link
      to={`edit-company/${data?._id}`}
      className="flex items-center justify-between py-2.5 border rounded-md bg-[#FAFAFA] hover:bg-white cursor-pointer grid grid-cols-3"
    >
      <div className="flex items-center gap-3 pl-5">
        <div className="h-4 w-4 border rounded-sm" />
        <label className="flex font-semibold text-xs text-primary cursor-pointer">
          {data?.refNo}
        </label>
      </div>
      <label className="flex font-semibold text-xs text-primary cursor-pointer">
        {data?.name}
      </label>
      <label className="flex font-semibold text-xs text-primary cursor-pointer">
        {data?.phoneNumber ?? "-"}
      </label>
    </Link>
  );
};

const CompaniesSection = () => {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyList("?page=1&limit=10").then((response) => {
      const { data, success } = response;
      if (success) {
        setCompanies(data);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="gap-2.5 flex mt-16 flex-col">
        {Array.from({ length: 10 }, (x, i) => i).map((key) => {
          return (
            <div className="flex justify-between">
              <div
                style={{
                  borderWidth: 1,
                }}
                className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
              >
                <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                <div className="h-3 rounded bg-gray-200 w-1/4"></div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (companies?.length === 0) {
    return (
      <div className="flex flex-1 h-screen flex-col items-center justify-center">
        <p className="text-primary font-semibold">No companies created</p>
        <p className="mt-7 mb-2">Create a company now!</p>
        <button
          onClick={() => navigate("/contacts/add-company")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Company
        </button>
      </div>
    );
  }

  return (
    <>
      {/* Search and filter section */}
      <div className="flex  items-center justify-between w-full mt-6">
        <div className="flex items-center gap-8">
          {/* Input container */}
          <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
              <label className="text-primary text-sm font-medium">
                Search by
              </label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
            <input
              className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
              placeholder="Search by Name, Title or Ref number..."
            />
            <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
          </div>
          <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
            <label className="text-primary text-sm font-medium">
              Filter status
            </label>
            <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
          </button>
        </div>

        <button
          onClick={() => navigate("/contacts/add-company")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Company
        </button>
      </div>

      {/* ScrollArea  */}
      <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar gap-2">
        <div className="grid grid-cols-3">
          <label className="font-bold text-primary text-xs pl-12">
            Reference No.
          </label>
          <label className="font-bold text-primary text-xs">Company Name</label>
          <label className="font-bold text-primary text-xs">
            Primary Contact
          </label>
        </div>
        {companies?.map((item) => {
          return <ItemCard key={item?._id} data={item} />;
        })}
      </div>
    </>
  );
};

export default CompaniesSection;
