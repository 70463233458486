import { useState } from "react";
import ChevronDown from "../../../../assets/svg/ChevronDown";
import ChevronLeftIcon from "../../../../assets/svg/ChevronLeftIcon";
import AboutSection from "./components/AboutSection";
import SkillSection from "./components/SkillSection";
import { useNavigate } from "react-router-dom";

const tabs = [
  "About",
  "Schedule Interview",
  "Send Assessment Link",
  "Activity History",
  "Messages",
];

const AddCandidatePage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4 overflow-y-auto max-h-screen">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center self-start"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      {/* Header section */}
      <div className="flex items-center justify-between rounded-lg border bg-[#FAFAFA] px-3 py-3.5 mt-5">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center h-12 w-12 rounded-full bg-[#00000033] text-white font-bold text-lg">
            JS
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs font-semibold text-[#6f6f6f]">
              Candidate Name
            </label>
            <label className="text-xs font-semibold text-[#6f6f6f]">
              Job Title
            </label>
          </div>
        </div>

        <div className="flex flex-col items-end gap-1">
          <div className="flex items-center justify-center text-primary font-medium text-primary text-sm gap-2">
            Status:
            <button className="flex items-center justify-center bg-green-500 rounded-md font-semibold py-1 gap-4 px-2 text-sm text-white">
              Open to offers
              <ChevronDown className={"w-4 h-4"} fill={"white"} />
            </button>
          </div>
          <label className="text-xs font-semibold text-primary italic">
            CA-FQ1478
          </label>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="flex items-center justify-between border-b px-3 mt-4">
        {tabs?.map((tab, tabIndex) => {
          return (
            <button
              key={tab}
              onClick={() => setActiveTab(tabIndex)}
              className={`${
                tabIndex === activeTab ? "border-pink" : "border-transparent"
              } text-primary text-sm font-medium border-b-2 py-1 px-3`}
            >
              {tab}
            </button>
          );
        })}
      </div>
      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
        {/* About Section */}
        <AboutSection />

        {/* Skill Section */}
        <SkillSection />
      </div>
    </div>
  );
};

export default AddCandidatePage;
