import { useEffect, useState } from "react";

const EditIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      stroke={fill}
      class={_class}
    >
      <path d="M10.0013 3.9985L12.0013 5.9985M8.66797 13.3318H14.0013M3.33464 10.6652L2.66797 13.3318L5.33464 12.6652L13.0586 4.94116C13.3086 4.69113 13.449 4.35205 13.449 3.9985C13.449 3.64494 13.3086 3.30587 13.0586 3.05583L12.944 2.94116C12.6939 2.6912 12.3549 2.55078 12.0013 2.55078C11.6478 2.55078 11.3087 2.6912 11.0586 2.94116L3.33464 10.6652Z" stroke="#6F6F6F" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default EditIcon;
