import * as React from "react";
const BagIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.15467 27.1667C5.54044 27.1667 5.028 26.9614 4.61733 26.5507C4.20667 26.14 4.00089 25.6272 4 25.012V11.988C4 11.3738 4.20578 10.8614 4.61733 10.4507C5.02889 10.04 5.54089 9.83427 6.15333 9.83338H12V7.98671C12 7.37338 12.2058 6.86093 12.6173 6.44938C13.0289 6.03782 13.5413 5.83249 14.1547 5.83338H17.8467C18.46 5.83338 18.9724 6.03871 19.384 6.44938C19.7956 6.86004 20.0009 7.37249 20 7.98671V9.83338H25.8467C26.46 9.83338 26.972 10.0392 27.3827 10.4507C27.7933 10.8623 27.9991 11.3747 28 11.988V25.0134C28 25.6267 27.7942 26.1392 27.3827 26.5507C26.9711 26.9623 26.4591 27.1676 25.8467 27.1667H6.15467ZM6.15467 25.8334H25.8467C26.0511 25.8334 26.2391 25.748 26.4107 25.5774C26.5822 25.4067 26.6676 25.2183 26.6667 25.012V11.988C26.6667 11.7827 26.5813 11.5943 26.4107 11.4227C26.24 11.2512 26.052 11.1658 25.8467 11.1667H6.15333C5.94889 11.1667 5.76089 11.252 5.58933 11.4227C5.41778 11.5934 5.33244 11.7818 5.33333 11.988V25.0134C5.33333 25.2178 5.41867 25.4058 5.58933 25.5774C5.76 25.7489 5.948 25.8343 6.15333 25.8334M13.3333 9.83338H18.6667V7.98671C18.6667 7.78227 18.5813 7.59427 18.4107 7.42271C18.24 7.25115 18.052 7.16582 17.8467 7.16671H14.1533C13.9489 7.16671 13.7609 7.25204 13.5893 7.42271C13.4178 7.59338 13.3324 7.78138 13.3333 7.98671V9.83338Z"
      fill="#6F6F6F"
    />
  </svg>
);
export default BagIcon;
