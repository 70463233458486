import React from "react";
import BagIcon from "../../../assets/svg/BagIcon";
import ClockIcon from "../../../assets/svg/ClockIcon";
import SkillsIcon from "../../../assets/svg/SkillsIcon";
import TrophyIcon from "../../../assets/svg/TrophyIcon";
import Divider from "../../../components/Divider/Divider";

const aboutItems = [
  {
    icon: <ClockIcon />,
    title: "5+ Years Job",
    subtitle: "Experienced",
  },
  {
    icon: <TrophyIcon />,
    title: "3 Certificates",
    subtitle: "Achieved",
  },
  {
    icon: <BagIcon />,
    title: "1 Internship",
    subtitle: "Completed",
  },
  {
    icon: <SkillsIcon />,
    title: "10 Skills",
    subtitle: "Expertise",
  },
];

const About = () => {
  return (
    <div className="h-full flex flex-1 flex-col py-[60px] px-[75px] overflow-y-scroll no-scrollbar">
      <div className="flex-col gap-5 mb-8">
        <Divider className="bg-[#DDDDDD]" />
        <div className="flex flex-1 flex-wrap gap-6 px-4 my-6">
          {aboutItems.map((item, index) => (
            <div
              key={index}
              className={`flex flex-1 items-center gap-3 ${
                index < aboutItems.length - 1 && "border-r-2"
              }`}
            >
              {item.icon}
              <div className="flex flex-col w-full">
                <span className="font-montserrat text-primary font-semibold text-base">
                  {item.title}
                </span>
                <span className="font-montserrat text-light-black font-medium text-xs">
                  {item.subtitle}
                </span>
              </div>
            </div>
          ))}
        </div>
        <Divider className="bg-[#DDDDDD]" />
      </div>
      <div className="flex flex-col">
        <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
          About
        </span>
        <span className="font-montserrat text-primary font-medium text-xs leading-5 mb-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          do eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmodLorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing
          elit, sed do eiusmodLorem ipsum dolor sit amet, consectetur adipiscing
          elit, sed do eiusmodLorem ipsum dolor sit amet, consectetur adipiscing
          elit, sed do eiusmodLorem ipsum dolor sit amet, consectetur adipiscing
          elit, sed do eiusmodLorem ipsum dolor sit amet, consectetur adipiscing
          elit, sed do eiusmod
        </span>
        <span className="font-montserrat text-primary font-medium text-xs leading-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          do eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        </span>
      </div>
    </div>
  );
};

export default About;
