import React from "react";
import { removeToken } from "../store/actions/auth.action";
import axios from "./axios.instance";
import { useDispatch } from "react-redux";

const useAxiosInstance = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");

        if (token) {
          config.headers["accessToken"] = token;
          return config;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          const originalConfig = error.response.config;
          if (
            originalConfig.url !== "/user/login" &&
            error.response &&
            error.response.status === 401
          ) {
            dispatch(removeToken());
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};
export { useAxiosInstance };
