import { useState } from "react";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import ChevronRightIcon from "../../assets/svg/ChevronRightIcon";
import IndustryIcon from "../../assets/svg/IndustryIcon";
import SkillIcon from "../../assets/svg/SkillIcon";
import StackIcon from "../../assets/svg/StackIcon";
import DoubleTick from "../../assets/svg/DoubleTick";
import { useForm } from "react-hook-form";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import { useNavigate } from "react-router-dom";
import Step1 from "./components/Step1";
import { useSnackbar } from "notistack";

const CreateJobPage = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({});

    const [steps, setSteps] = useState([
        {
            label: "Company",
            icon: <IndustryIcon className={"w-5 h-5"} fill={"#6f6f6f"} />,
            isCompleted: false,
        },
        {
            label: "Basic Details",
            icon: <StackIcon className={"w-5 h-5"} />,
            isCompleted: false,
        },
        {
            label: "Skills",
            icon: <SkillIcon className={"w-5 h-5"} fill={"#6f6f6f"} />,
            isCompleted: false,
        },
    ]);
    const [createNewCompany, setCreateNewCompany] = useState(false);
    const [activeStep, setActiveStep] = useState(1);

    const handleNext = () => {
        if (createNewCompany) {
            setCreateNewCompany(false);
            return;
        }
        setSteps((prev) => {
            return prev?.map((item, itemIndex) => {
                if (itemIndex + 1 === activeStep) {
                    return { ...item, isCompleted: true };
                } else {
                    return item;
                }
            });
        });
        if (activeStep !== 3) {
            setActiveStep((prev) => prev + 1);
        } else if (activeStep === 3) {
            enqueueSnackbar("Job Created Successfully!", { variant: "success" });
        }
    };

    const handlePrevious = () => {
        setActiveStep((prev) => prev - 1);
    };

    return (
        <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
            <button onClick={() => navigate(-1)} className="flex items-center">
                <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
                <label className="text-xs font-medium text-primary">
                    Back to listing
                </label>
            </button>
            <form className="flex flex-col items-center w-full h-full">
                <span className="font-medium text-sm">Add Company</span>
                {/* Progress indicator section */}
                <div className="flex justify-between items-center mt-4 border-b pb-5 w-full">
                    <button
                        type="button"
                        onClick={handlePrevious}
                        className={`bg-[#ececec] rounded-md text-xs px-4 py-2 self-end ${activeStep > 1 ? "" : "opacity-0"
                            }`}
                    >
                        Previous
                    </button>
                    {/* Indicators */}
                    <div className="flex gap-4 items-center">
                        {steps?.map((step, stepIndex) => {
                            const isActive = stepIndex + 1 === activeStep;
                            return (
                                <>
                                    {stepIndex !== 0 ? (
                                        <div className="w-14 h-px border-dashed border-b border-light-black" />
                                    ) : null}
                                    <div
                                        className={`flex gap-6 ${isActive ? "bg-light-white-200" : ""
                                            } ${step?.isCompleted || isActive ? "" : "opacity-40"
                                            } rounded-2xl px-4 py-2 items-center`}
                                    >
                                        <div className="flex items-center gap-2">
                                            {step?.icon}
                                            <label className="font-medium text-xs text-primary">
                                                {step?.label}
                                            </label>
                                        </div>
                                        {step?.isCompleted ? (
                                            <DoubleTick className={"w-3 h-3"} fill="#2e2e2e" />
                                        ) : (
                                            <ChevronRightIcon className={"w-3 h-3"} fill="#2e2e2e" />
                                        )}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <button
                        type="button"
                        onClick={handleNext}
                        className="bg-[#ececec] rounded-md text-xs px-4 py-2 self-end"
                    >
                        {createNewCompany && activeStep === 1
                            ? "Create"
                            : activeStep === 1
                                ? " Skip"
                                : activeStep === 2
                                    ? "Next"
                                    : "Finish"}
                    </button>
                </div>
                {/* Body Container */}
                <div className="flex flex-col py-10 max-h-fit overflow-y-scroll no-scrollbar w-full">
                    {activeStep === 1 ? (
                        <Step1
                            setCreateNewCompany={setCreateNewCompany}
                            createNewCompany={createNewCompany}
                        />
                    ) : activeStep === 2 ? (
                        <Step2 control={control} errors={errors} />
                    ) : (
                        <Step3 />
                    )}
                </div>
            </form>
        </div>
    );
};

export default CreateJobPage;