import * as React from "react";
const CameraFilledIcon = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_4944_14504)">
      <rect
        x="3.71436"
        y="2.37671"
        width="16.6234"
        height="16.6234"
        rx="8.31169"
        fill="white"
      />
      <path
        d="M16.4847 6.59273V5.14722H15.521V6.59273H14.0755V7.5564H15.521V9.00191H16.4847V7.5564H17.9302V6.59273M15.0392 9.48375V8.03824H13.5936V6.59273H10.2208L9.35348 7.5564H7.8116C7.28158 7.5564 6.84792 7.99005 6.84792 8.52008V14.3021C6.84792 14.8321 7.28158 15.2658 7.8116 15.2658H15.521C16.051 15.2658 16.4847 14.8321 16.4847 14.3021V9.48375M11.6663 13.8203C9.52212 13.8203 8.4428 11.228 9.96059 9.70539C11.4784 8.18761 14.0755 9.26692 14.0755 11.4111C14.0755 12.741 12.9962 13.8203 11.6663 13.8203ZM13.2082 11.4111C13.2082 12.7843 11.5458 13.4734 10.5773 12.5C9.60403 11.5316 10.2931 9.86922 11.6663 9.86922C12.0748 9.87049 12.4663 10.0333 12.7552 10.3222C13.044 10.6111 13.2069 11.0026 13.2082 11.4111Z"
        fill="#2E2E2E"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4944_14504"
        x="0.943793"
        y="0.298787"
        width="22.1647"
        height="22.1644"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.692641" />
        <feGaussianBlur stdDeviation="1.38528" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4944_14504"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4944_14504"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default CameraFilledIcon;
