import { useNavigate } from "react-router-dom";
import ChevronDown from "../../assets/svg/ChevronDown";
import PlusIcon from "../../assets/svg/PlusIcon";
import SearchIcon from "../../assets/svg/SearchIcon";

const JobPage = () => {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
            {/* Search and filter section */}
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-8">
                    {/* Input container */}
                    <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
                        <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
                            <label className="text-primary text-sm font-medium">
                                Search by
                            </label>
                            <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
                        </button>
                        <input
                            className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
                            placeholder="Search Reference, Job Title or Company Name..."
                        />
                        <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
                    </div>
                    <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
                        <label className="text-primary text-sm font-medium">
                            All Jobs
                        </label>
                        <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
                    </button>
                </div>

                <button
                    onClick={() => navigate("create")}
                    className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
                >
                    <PlusIcon className={"w-4 h-4"} fill={"white"} />
                    Create Job Post
                </button>
            </div>

            {/* List container */}
            <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar gap-2">
                <table class="table-auto mt-3">
                    <thead className="border-b">
                        <tr>
                            <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">Job Reference</th>
                            <th className="text-left font-semibold py-3 text-sm text-primary">Job Title</th>
                            <th className="text-left font-semibold py-3 text-sm text-primary">Company Name</th>
                            <th className="text-left font-semibold py-3 text-sm text-primary">Publish date</th>
                            <th className="text-left font-semibold py-3 pr-6 text-sm text-primary">Live</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4, 5, 6, , 2, 3, 4, 5, 6, 2, 3, 4, 5, 6]?.map(() => {
                            return <tr
                                className="hover:bg-light-white-700 cursor-pointer"
                            >
                                <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">AB-1234</td>
                                <td className="font-medium py-3 border-b text-xs text-light-black">Senior Search engine optimization</td>
                                <td className="font-medium py-3 border-b text-xs text-light-black">Lorem Ipsum Solutions PVT. Ltd.</td>
                                <td className="font-medium py-3 border-b text-xs text-light-black">24 Jan 2024</td>
                                <td className="font-medium py-3 pr-6 border-b text-xs text-light-black">Live</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {/* <div className="mt-5">
                <div className="w-full gap-1 grid grid-cols-5">
                    <label className="font-semibold text-primary text-sm">Job Reference</label>
                    <label className="font-semibold text-primary text-sm">Job Title</label>
                    <label className="font-semibold text-primary text-sm">Company Name</label>
                    <label className="font-semibold text-primary text-sm">Publish date</label>
                    <label className="font-semibold text-primary text-sm">Status</label>
                </div>
                {
                    [1, 2, 3, 4, 5]?.map(() => {
                        return <div className="w-full gap-1 grid grid-cols-5">
                            <label className="font-medium text-primary text-sm">AB-1234</label>
                            <label className="font-medium text-primary text-sm">Senior Search engine optimization </label>
                            <label className="font-medium text-primary text-sm">Lorem Ipsum Solutions PVT. Ltd.</label>
                            <label className="font-medium text-primary text-sm">24 Jan 2024</label>
                            <label className="font-medium text-primary text-sm">Live</label>
                        </div>
                    })
                }
            </div> */}
            </div>
        </div>
    );
}

export default JobPage