// import * as React from "react";
// import { Controller } from "react-hook-form";
// import { countryCodes } from "../../utils/countryCode";
// import { useState } from "react";
// import { useEffect } from "react";
// import { extractDialCode } from "../../utils/helperFunction";

// const PhoneInput = ({
//     label,
//     bgColor,
//     activeOutlineColor,
//     inActiveOutlineColor,
//     className,
//     onChange,
//     value
// }) => {
//     const [isFocused, setIsFocused] = useState(false);
//     const [countryCode, setCountryCode] = useState("+44");
//     const [number, setNumber] = useState()

//     useEffect(() => {
//         onChange(countryCode + number)
//     }, [countryCode, number])


//     useEffect(() => {
//         if (value) {
//             const { dialCode, localNumber } = extractDialCode(value)
//             setCountryCode(dialCode)
//             setNumber(localNumber)
//         }
//         console.log('====================================', value);
//         console.log(extractDialCode(value));
//         console.log('====================================');
//         // onChange(countryCode + number)
//     }, [value])

//     return <div className="flex flex-col w-full">
//         {label && (
//             <span className="font-montserrat text-primary font-medium text-xs mb-2">
//                 {label}
//             </span>
//         )}
//         <div
//             className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border ${bgColor ?? "bg-[#ffffff]"
//                 } ${isFocused ? activeOutlineColor : inActiveOutlineColor
//                 } ${className}`}
//         >
//             <select
//                 className="w-14 outline-none bg-transparent"
//                 name={label}
//                 id={label}
//                 value={countryCode}
//                 onChange={(e) => setCountryCode(e?.target?.value)}
//             >
//                 {countryCodes?.map((item) => {
//                     return <option value={item?.dial_code}>{item?.dial_code} &nbsp;&nbsp; {item?.name}</option>;
//                 })}
//             </select>
//             <input
//                 // disabled={disabled}
//                 value={number}
//                 onChange={(e) => setNumber(e?.target?.value)}
//                 onFocus={() => setIsFocused(true)}
//                 onBlur={() => setIsFocused(false)}
//                 className="flex-1 bg-transparent outline-none whitespace-pre-wrap break-words overflow-hidden pl-2"
//                 placeholder={"Enter your number"}
//                 type={'number'}
//                 style={{ height: "auto", lineHeight: "1.5" }}
//             />
//         </div>
//     </div>
// }


// const CustomPhoneNumberInput = ({
//     label,
//     name,
//     control,
//     bgColor,
//     className,
//     activeOutlineColor = "border-gray",
//     inActiveOutlineColor = "border-transparent",
//     errors
// }) => {
//     return (
//         <div className="flex flex-col w-full">
//             <Controller
//                 name={name}
//                 control={control}
//                 defaultValue=""
//                 rules={{
//                     validate: (value) => {
//                         return value.length >= 10 || "Invalid phone number";
//                     },
//                 }}
//                 render={({ field: { onChange, value } }) => (
//                     <PhoneInput
//                         className={className}
//                         label={label}
//                         value={value}
//                         onChange={onChange}
//                         bgColor={bgColor}
//                         activeOutlineColor={activeOutlineColor}
//                         inActiveOutlineColor={inActiveOutlineColor}
//                     />
//                     // <PhoneInput
//                     //     {...field}
//                     //     country={lngCode}
//                     //     placeholder="Enter your phone number"
//                     //     onChange={field.onChange}
//                     //     localization={localeCodes[lngCode]}
//                     //     inputProps={{
//                     //         name,
//                     //         required: true,
//                     //         // autoFocus: true,
//                     //     }}
//                     //     isValid={(inputNumber, country) => {
//                     //         return inputNumber.length === 10;
//                     //     }}
//                     //     className={`!w-full h-10`}
//                     // />
//                 )}
//             />
//             {errors?.message && (
//                 <span className="whitespace-pre text-xs mt-2 text-orange-700">
//                     {errors?.message}
//                 </span>
//             )}
//         </div>
//     );
// };

// export default CustomPhoneNumberInput;


import * as React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import es from "react-phone-input-2/lang/es.json";
import fr from "react-phone-input-2/lang/fr.json";
import ru from "react-phone-input-2/lang/ru.json";
import { Controller } from "react-hook-form";

const CustomPhoneNumberInput = ({ label, name, control, errors }) => {
    const localeCodes = { es, fr, ru };
    const lngCode = "us";

    return (
        <div className="flex flex-col w-full">
            {label && (
                <span className="font-montserrat text-primary font-medium text-xs mb-2">
                    {label}
                </span>
            )}
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{
                    validate: (value) => {
                        return value.length >= 10 || "Invalid phone number";
                    },
                }}
                render={({ field }) => {
                    return <PhoneInput
                        {...field}
                        value={field.value}
                        country={lngCode}
                        placeholder="Enter your phone number"
                        onChange={field.onChange}
                        localization={localeCodes[lngCode]}
                        inputProps={{
                            name,
                            required: true,
                            // autoFocus: true,
                        }}
                        isValid={(inputNumber, country) => {
                            return inputNumber.length === 10;
                        }}
                        className={`!w-full h-10`}
                        inputStyle={{
                            width: "100%",
                            height: '2.5rem',
                            backgroundColor: 'white',
                            border: '1px solid lightgray'
                        }}
                    />
                }}
            />
            {errors?.message && (
                <span className="whitespace-pre text-xs mt-2 text-orange-700">
                    {errors?.message}
                </span>
            )}
        </div>
    );
};

export default CustomPhoneNumberInput;
