//Assessment section
import handleError from "./handle-error";
import api from "../api";
import axios from "../utils/axios.instance";

export const createAssessment = async (payload) => {
  try {
    const { data } = await axios.post(api.createAssessment, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const listAssessment = async (query) => {
  try {
    const { data } = await axios.get(api.listAssessment);
    return data;
  } catch (err) {
    console.log(err);

    return handleError(err);
  }
};

export const getAssessmentDetails = async (assessmentId) => {
  try {
    const { data } = await axios.get(
      api.assessmentDetails + `/${assessmentId}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteAssessment = async (assessmentId) => {
  try {
    const { data } = await axios.delete(
      api.deleteAssessment + `/${assessmentId}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

//Section for assessment
export const createAssessmentSection = async (payload) => {
  try {
    const { data } = await axios.post(api.createSection, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateAssessmentSection = async (sectionId, title) => {
  try {
    const { data } = await axios.patch(
      api.updateSection + "/" + sectionId + "/" + title
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteAssessmentSection = async (sectionId) => {
  try {
    const { data } = await axios.delete(api.deleteSection + `/${sectionId}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

//Question for assessment
export const createAssessmentQuestion = async (payload) => {
  try {
    const { data } = await axios.post(api.createQuestion, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateAssessmentQuestion = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateQuestion, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteAssessmentQuestion = async (questionId) => {
  try {
    const { data } = await axios.delete(api.deleteQuestion + `/${questionId}`);
    return data;
  } catch (err) {
    return handleError(err);
  }
};
