import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../assets/svg/DashboardIcon";
import AssessmentIcon from "../../assets/svg/AssessmentIcon";
import TransactionIcon from "../../assets/svg/TransactionIcon";
import ContactsIcon from "../../assets/svg/ContactsIcon";
import CalenderIcon from "../../assets/svg/CalenderIcon";
import { useState } from "react";
import { useSelector } from "react-redux";
import ProfileIcon from "../../assets/svg/ProfileIcon";
import MyContactsIcon from "../../assets/svg/MyContactsIcon";
import AnswersIcon from "../../assets/svg/AnswersIcon";
import Divider from "../Divider/Divider";
import CareerIcon from "../../assets/svg/CareerIcon";

const Sidebar = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const recruiterRoutes = [
    {
      label: "Dashboard",
      route: "/dashboard",
      icon: <DashboardIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "My Assessment",
      route: "/my-assessments",
      icon: <AssessmentIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Contacts",
      route: "/contacts",
      icon: <ContactsIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Calendar",
      route: "/calendar",
      icon: <CalenderIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Job",
      route: "/job",
      icon: <CareerIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Transactions",
      route: "/transactions",
      icon: <TransactionIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
  ];

  const candidateRoutes = [
    {
      label: "Dashboard",
      route: "/dashboard",
      icon: <DashboardIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "My Assessment",
      route: "/my-assessments/candidate-Assessments",
      icon: <AssessmentIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "My Profile",
      route: "/my-profile",
      icon: <ProfileIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "My Contacts",
      route: "/my-contacts",
      icon: <MyContactsIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Transactions",
      route: "/transactions",
      icon: <TransactionIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
    {
      label: "Answers",
      route: "/answers",
      icon: <AnswersIcon className={"w-3.5 h-3.5"} fill="#4d4d4d" />,
    },
  ];

  const routes = user?.userType === 2 ? recruiterRoutes : candidateRoutes;

  return (
    <>
      {/* Mobile menu button */}
      <button
        className="xl:hidden fixed top-4 left-4 z-20"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? "Close" : "Menu"}
      </button>

      {/* Sidebar */}
      <div
        className={` xl:static top-0 left-0 h-full bg-white z-10 transition-all duration-300 ease-in-out
                    ${isOpen ? "w-48" : "w-16 xl:w-48"} overflow-hidden`}
      >
        <div
          className={`flex flex-col ${isOpen ? "w-48" : "w-16 pl-4 xl:w-48"
            } gap-3 pt-10 pb-5`}
        >
          {routes?.map((route) => {
            const isActive = pathname.includes(route?.route);
            return (
              <>
                {user?.userType === 3 && route?.label === "Answers" && (
                  <Divider className="bg-[#DDDDDD] " />
                )}
                <Link
                  to={route?.route}
                  key={route.label}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className={`flex items-center justify-end pr-4 xl:pr-0 xl:justify-start relative gap-2 rounded-lg ${isActive ? "bg-[#F2F2F2]" : ""
                    } py-2.5 pl-2`}
                >
                  <div className="w-3.5">{route?.icon}</div>
                  <label className="font-medium text-xs cursor-pointer text-[#4D4D4D] hidden xl:block">
                    {route?.label}
                  </label>
                  <div
                    className={`w-2 h-full right-0 absolute ${isActive ? "bg-secondary" : "white"
                      } rounded-l`}
                  />
                </Link>
              </>
            );
          })}
        </div>
      </div>

      {/* Overlay for mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-5 xl:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Sidebar;
