export const experiences = [
  {
    duration: "Dec 2017 - Present",
    company: "Icoderz",
    location: "Ahmedabad",
    role: "Crisis Intervention Specialist",
    description:
      "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lore lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lore",
    skills: [
      { skill: "JavaScript", value: 6 },
      { skill: "React.js", value: 8 },
      { skill: "MySQL", value: 6 },
      { skill: "RESTful", value: 4 },
    ],
  },
  {
    duration: "Jan 2015 - Nov 2017",
    company: "TechCorp",
    location: "Mumbai",
    role: "Full Stack Developer",
    description:
      "Developed full-stack applications, and worked on database management. lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lore",
    skills: [
      { skill: "Node.js", value: 7 },
      { skill: "MongoDB", value: 5 },
      { skill: "Express.js", value: 6 },
      { skill: "Angular", value: 4 },
    ],
  },
  {
    duration: "Jun 2012 - Dec 2014",
    company: "Innovate Solutions",
    location: "Pune",
    role: "Software Engineer",
    description:
      "Worked on various software projects, and improved UI/UX design. lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lore",
    skills: [
      { skill: "HTML/CSS", value: 8 },
      { skill: "Java", value: 5 },
      { skill: "SQL", value: 7 },
      { skill: "Git", value: 6 },
    ],
  },
];

export const skillsData = [
  { skill: "JavaScript", level: 6, experience: 9, lastUsed: "Current" },
  { skill: "React.js", level: 8, experience: 5, lastUsed: "Current" },
  { skill: "RESTful", level: 5, experience: 4, lastUsed: "Current" },
  { skill: "MySQL", level: 7, experience: 5, lastUsed: "1 week ago" },
  { skill: "Python", level: 9, experience: 5, lastUsed: "1 week ago" },
  { skill: "Wireframing", level: 5, experience: 8, lastUsed: "1 month ago" },
  { skill: "Mockups", level: 5, experience: 6, lastUsed: "1 month ago" },
  { skill: "JavaScript", level: 6, experience: 9, lastUsed: "Current" },
  { skill: "React.js", level: 8, experience: 5, lastUsed: "Current" },
  { skill: "RESTful", level: 5, experience: 4, lastUsed: "Current" },
  { skill: "MySQL", level: 7, experience: 5, lastUsed: "1 week ago" },
  { skill: "Python", level: 9, experience: 5, lastUsed: "1 week ago" },
  { skill: "Wireframing", level: 5, experience: 8, lastUsed: "1 month ago" },
  { skill: "Mockups", level: 5, experience: 6, lastUsed: "1 month ago" },
];
