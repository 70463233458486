import * as React from "react";
const GlobeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#6F6F6F" />
    <path
      d="M12.0001 17.9261C15.2729 17.9261 17.9261 15.2729 17.9261 12.0001C17.9261 8.72735 15.2729 6.07422 12.0001 6.07422C8.72735 6.07422 6.07422 8.72735 6.07422 12.0001C6.07422 15.2729 8.72735 17.9261 12.0001 17.9261Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.07422 12.001H17.9261"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0003 6.07422C13.4825 7.69695 14.3248 9.80283 14.3706 12.0001C14.3248 14.1975 13.4825 16.3033 12.0003 17.9261C10.518 16.3033 9.67566 14.1975 9.62988 12.0001C9.67566 9.80283 10.518 7.69695 12.0003 6.07422V6.07422Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default GlobeIcon;
