import { useForm } from "react-hook-form";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import { useEffect, useState } from "react";
import PlusIcon from "../../../../../assets/svg/PlusIcon";
import CustomPhoneNumberInput from "../../../../../components/CustomPhoneNumberInput/CustomPhoneNumberInput";
import regexList from "../../../../../utils/regexList";
import TrashIcon from "../../../../../assets/svg/TrashIcon";
import EditIcon from "../../../../../assets/svg/EditIcon";
import SaveIcon from "../../../../../assets/svg/SaveIcon";
import { useParams } from "react-router-dom";
import {
  addCompanyContact,
  deleteCompanyContact,
  getCompanyContacts,
  updateCompanyContact,
} from "../../../../../endpoints/company.service";
import {
  extractDialCode,
  formatPhoneNumber,
} from "../../../../../utils/helperFunction";
import { useSnackbar } from "notistack";

const ShowLabel = ({ label, value }) => {
  return (
    <div className="flex flex-col">
      <span className="font-montserrat text-primary font-normal text-xs mb-2">
        {label}
      </span>
      <span className="font-montserrat text-primary font-medium text-xs mb-2">
        {value}
      </span>
    </div>
  );
};

const RenderFormContact = ({
  contactSection,
  companyId,
  index,
  setContactList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: contactSection?.fullName,
      email: contactSection?.email,
      jobTitle: contactSection?.jobTitle,
      mobileNo: (
        contactSection?.countryCode + contactSection?.mobileNumber
      ).toString(),
      landlineNo: (
        contactSection?.landLineCode + contactSection?.landLineNumber
      ).toString(),
    },
  });
  const [isEdit, setIsEdit] = useState(!contactSection?.fullName);

  const handleSave = (formData) => {
    setIsEdit(false);
    if (contactSection?._id) {
      const payload = {
        ...formData,
        companyId,
        contactId: contactSection?._id,
        // countryCode: extractDialCode("+" + formData?.mobileNo)?.dialCode,
        // mobileNumber: extractDialCode("+" + formData?.mobileNo)?.localNumber,
        // landLineCode: extractDialCode("+" + formData?.landlineNo)?.dialCode,
        // landLineNumber: extractDialCode("+" + formData?.landlineNo)?.localNumber,
        countryCode: extractDialCode(
          !formData?.mobileNo?.includes("+") ? "+" : "" + formData?.mobileNo
        )?.dialCode,
        mobileNumber: extractDialCode(
          !formData?.mobileNo?.includes("+") ? "+" : "" + formData?.mobileNo
        )?.localNumber,
        landLineCode: extractDialCode(
          !formData?.landlineNo?.includes("+") ? "+" : "" + formData?.landlineNo
        )?.dialCode,
        landLineNumber: extractDialCode(
          !formData?.landlineNo?.includes("+") ? "+" : "" + formData?.landlineNo
        )?.localNumber,
      };
      updateCompanyContact(payload).then((response) => {
        const { success, data } = response;
        if (success) {
          enqueueSnackbar("Contacts udpated successfully", {
            variant: "success",
          });
          setContactList((prev) => {
            return prev?.map((item) => {
              if (item?._id === contactSection?._id) {
                return data;
              } else {
                return item;
              }
            });
          });
        }
      });
    } else {
      const payload = {
        ...formData,
        companyId,
        countryCode: extractDialCode("+" + formData?.mobileNo)?.dialCode,
        mobileNumber: extractDialCode("+" + formData?.mobileNo)?.localNumber,
        landLineCode: extractDialCode("+" + formData?.landlineNo)?.dialCode,
        landLineNumber: extractDialCode("+" + formData?.landlineNo)
          ?.localNumber,
      };

      addCompanyContact(payload).then((response) => {
        const { success, data } = response;
        if (success) {
          enqueueSnackbar("Contacts added successfully", {
            variant: "success",
          });
          setContactList((prev) => {
            return [data, ...prev];
          });
        }
      });
    }
  };
  const handleDelete = () => {
    deleteCompanyContact(contactSection?._id).then((response) => {
      const { success } = response;
      if (success) {
        enqueueSnackbar("Contacts deleted successfully", {
          variant: "success",
        });
        setContactList((prev) =>
          prev?.filter((item) => item?._id !== contactSection?._id)
        );
      }
    });
  };

  useEffect(() => {
    if (contactSection) {
      ["fullName", "email", "jobTitle"]?.forEach((item) =>
        setValue(item, contactSection[item])
      );
      setValue(
        "mobileNo",
        (contactSection?.countryCode + contactSection?.mobileNumber).toString()
      );
      setValue(
        "landlineNo",
        (
          contactSection?.landLineCode + contactSection?.landLineNumber
        ).toString()
      );
      // setValue('phoneNumber', contactSection?.countryCode + contactSection?.phoneNumber)
    }
  }, [contactSection]);

  return (
    <form
      key={contactSection.id}
      onSubmit={handleSubmit(handleSave)}
      className="flex gap-4 bg-[#FAFAFA] p-3.5 rounded-xl border justify-between"
    >
      <div className="flex w-full gap-3 grid grid-cols-3">
        <div className="flex flex-col gap-4">
          {!isEdit ? (
            <ShowLabel label={"Full Name"} value={contactSection?.fullName} />
          ) : (
            <CustomInput
              label="Full Name"
              placeholder="Enter Full Name"
              bgColor="bg-white"
              inActiveOutlineColor="border-[#E9EAF0]"
              name={"fullName"}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
              labelStyle={`text-xs`}
              className={`border !rounded-[8px]`}
            />
          )}

          {!isEdit ? (
            <ShowLabel
              label={"Mobile Number"}
              value={formatPhoneNumber(
                contactSection?.countryCode + contactSection?.mobileNumber
              )}
            />
          ) : (
            <CustomInput
              type="phone"
              filled={false}
              label={"Mobile Number"}
              name={"mobileNo"}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />
          )}
        </div>
        <div className="flex flex-col gap-4">
          {!isEdit ? (
            <ShowLabel label={"Email Address"} value={contactSection?.email} />
          ) : (
            <CustomInput
              label={"Email Address"}
              placeholder={"abc@gmail.com"}
              inActiveOutlineColor="border border-px"
              bgColor={"bg-white"}
              name={"email"}
              rules={{
                required: "This field is required.",
                pattern: {
                  value: regexList?.email, // Regex for email validation
                  message: "Please enter a valid email address.", // Custom error message for pattern validation
                },
              }}
              control={control}
              errors={errors}
            />
          )}

          {!isEdit ? (
            <ShowLabel
              label={"Landline Number"}
              value={formatPhoneNumber(
                contactSection?.landLineCode + contactSection?.landLineNumber
              )}
            />
          ) : (
            <CustomInput
              type="phone"
              filled={false}
              label={"Landline Number"}
              name={"landlineNo"}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />
          )}
        </div>
        <div className="flex flex-col gap-4">
          {!isEdit ? (
            <ShowLabel label="Job Title" value={contactSection?.jobTitle} />
          ) : (
            <CustomInput
              label="Job Title"
              placeholder="Enter job title"
              bgColor="bg-white"
              inActiveOutlineColor="border-[#E9EAF0]"
              name={"jobTitle"}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
              labelStyle={`text-xs`}
              className={`border !rounded-[8px]`}
            />
          )}
        </div>
      </div>
      <div className="flex gap-2 items-start">
        {!isEdit ? (
          <div onClick={() => setIsEdit(true)}>
            <EditIcon className={"w-5 h-5"} fill={"#6F6F6F"} />
          </div>
        ) : (
          <button type="submit">
            <SaveIcon className={"w-5 h-5"} fill={"#6F6F6F"} />
          </button>
        )}
        <button onClick={handleDelete} type="button">
          <TrashIcon className={"w-5 h-5"} fill={"black"} />
        </button>
      </div>
    </form>
  );
};

const AddContactSection = () => {
  const { companyId } = useParams();
  const [contactList, setContactList] = useState([
    {
      fullName: "",
      email: "",
      jobTitle: "",
      mobileNo: "",
      landlineNo: "",
    },
  ]);

  const handleAddContact = () => {
    setContactList((prev) => {
      return [
        ...prev,
        {
          // id: prev?.length + 1,
          fullName: "",
          email: "",
          jobTitle: "",
          mobileNo: "",
          landlineNo: "",
        },
      ];
    });
  };

  useEffect(() => {
    if (companyId) {
      getCompanyContacts(companyId).then((response) => {
        const { data, success } = response;
        if (success) {
          setContactList((prev) => {
            return [
              ...data,
              {
                fullName: "",
                email: "",
                jobTitle: "",
                mobileNo: "",
                landlineNo: "",
              },
            ];
          });
        }
      });
    }
  }, [companyId]);

  return (
    <div className="flex flex-col gap-6 border rounded-lg px-4 py-4 mt-5">
      <div className="flex items-center justify-between">
        <label className="text-primary font-semibold text-sm text-center">
          Company Contacts
        </label>
        <button
          onClick={handleAddContact}
          className="flex items-center justify-center gap-3 border text-sm text-primary rounded-md px-2 py-1.5 font-semibold w-max"
        >
          <PlusIcon className={"w-4 h-4"} fill={"#2e2e2e"} />
          Add Contact
        </button>
      </div>

      {contactList.map((contactSection, index) => (
        <RenderFormContact
          key={contactSection._id}
          contactSection={contactSection}
          setContactList={setContactList}
          companyId={companyId}
          index={index}
        />
      ))}
    </div>
  );
};

export default AddContactSection;
