import PlusIcon from "../../../assets/svg/PlusIcon"
import SaveIcon from "../../../assets/svg/SaveIcon"
import ThreeDotsVertical from "../../../assets/svg/ThreeDotsVertical"
import Trash from "../../../assets/svg/Trash"
import TrashIcon from "../../../assets/svg/TrashIcon"
import CustomSlider from "../../../components/CustomSlider/CustomSlider"

const ProgressBar = ({
    value
}) => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => {
        return <div
            key={item}
            className={`w-4 h-3 ${value >= (item + 1) ? 'bg-[#EFBF04]' : 'bg-[#ececec]'}`}
        />
    })
}

const SkillInput = () => {
    return <div className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg mb-5">
        <div
            style={{ width: "25%" }}
            className="flex font-semibold text-sm text-primary"
        >
            <input
                className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
                placeholder="Javascript"
            />
        </div>
        <div
            style={{ width: "25%" }}
            className="flex font-semibold text-sm text-primary"
        >
            <CustomSlider
                step={1}
                min={0}
                max={10}
                fillerClass={`h-[5px]`}
                defaultValue={5}
                sliderValues={4}
                labelStyle={`text-xs`}
            />
        </div>
        <label
            style={{ width: "25%" }}
            className="flex font-semibold text-sm text-primary justify-center"
        >
            <input
                className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
                placeholder="9"
            />
        </label>
        <div
            style={{ width: "20%" }}
            className="flex text-sm text-primary"
        >
            <select className="px-4 py-2 rounded-md text-xs">
                <option className="font-medium text-primary">Current</option>
                <option className="font-medium text-primary">Last week</option>
            </select>
        </div>
        <div className="flex flex-col w-[5%] items-end gap-3">
            <SaveIcon
                className={'w-4 h-4'}
            />
            <TrashIcon
                className={'w-4 h-4'}
            />
        </div>
    </div>
}

const Step3 = () => {
    return <div className="flex flex-col items-center">
        <label className="font-semibold">Skills Required</label>
        <div className="flex flex-col w-full px-4 py-8">
            <div className="flex items-center gap-4">
                <label className="font-medium text-sm text-primary">Click here to add a skill</label>
                <button
                    type="button"
                    className="flex items-center text-xs text-primary font-medium bg-[#ececec] px-1.5 py-1 rounded-md gap-1"
                >
                    <PlusIcon className={'w-3.5 h-3.5'} fill={'black'} />
                    Add Skill
                </button>
            </div>
            {/* List Container */}
            <div className="flex flex-col mt-8">
                <div className="flex items-center pb-3 px-4">
                    <label
                        style={{ width: "25%" }}
                        className="flex font-semibold text-sm text-primary"
                    >
                        Skill
                    </label>
                    <label
                        style={{ width: "25%" }}
                        className="flex font-semibold text-sm text-primary"
                    >
                        Level out of 10
                    </label>
                    <label
                        style={{ width: "25%" }}
                        className="flex font-semibold text-sm text-primary justify-center"
                    >
                        Years of experience
                    </label>
                    <label
                        style={{ width: "20%" }}
                        className="flex font-semibold text-sm text-primary"
                    >
                        Last used
                    </label>
                    <div className="w-[5%]" />
                </div>

                <SkillInput />

                <div className="px-4">
                    {
                        [1, 2, 3, 4, 5]?.map((item, index) => {
                            return <div
                                key={item}
                                className={`flex items-center py-4 ${index !== 0 ? 'border-t' : ''}`}>
                                <label
                                    style={{ width: "25%" }}
                                    className="flex text-xs font-medium text-primary"
                                >
                                    React.js
                                </label>
                                <div
                                    style={{ width: "25%" }}
                                    className="flex text-xs font-medium text-primary gap-1.5"
                                >
                                    <ProgressBar
                                        value={item}
                                    />
                                </div>
                                <label
                                    style={{ width: "25%" }}
                                    className="flex text-xs font-medium text-primary justify-center"
                                >
                                    7
                                </label>
                                <label
                                    style={{ width: "20%" }}
                                    className="flex text-xs font-medium text-primary"
                                >
                                    Current
                                </label>
                                <div
                                    style={{ width: "5%" }}
                                    className="flex text-xs font-medium text-primary justify-center"
                                >
                                    <ThreeDotsVertical
                                        className={'w-4 h-4'}
                                        fill={'#6f6f6f'}
                                    />
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            {/* <table class="table-auto mt-3">
                <thead className="border-b">
                    <tr>
                        <th className="text-left font-semibold py-3 text-sm text-primary">Skill</th>
                        <th className="text-left font-semibold py-3 text-sm text-primary">Level out of 10</th>
                        <th className="text-left font-semibold py-3 text-sm text-primary w-40">Years of experience</th>
                        <th className="text-left font-semibold py-3 text-sm text-primary">Last used</th>
                        <th className="text-left font-semibold py-3 text-sm text-primary"></th>
                    </tr>
                </thead>
                <tbody>
                    {[1, 2, 3, 4, 5, 6, , 2, 3, 4, 5, 6, 2, 3, 4, 5, 6]?.map(() => {
                        return <tr
                            className="hover:bg-light-white-700 cursor-pointer"
                        >
                            <td className="font-medium py-3 border-b text-xs text-light-black">React.js</td>
                            <td className="font-medium py-3 border-b text-xs text-light-black">
                            <ProgressBar value={5} total={10} />
                            </td>
                            <td className="font-medium py-3 border-b text-xs text-light-black">7</td>
                            <td className="font-medium py-3 border-b text-xs text-light-black">Current</td>
                            <td className="font-medium py-3 border-b text-xs text-light-black">Live</td>
                        </tr>
                    })}
                </tbody>
            </table> */}
        </div>
    </div>
}

export default Step3