import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import Quill's styles

const QuillEditor = ({ onChange, value }) => {
  const quillRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    const quill = new Quill(quillRef.current, {
      theme: "snow",
      modules: {
        toolbar: {
          container: "#custom-toolbar", // Use custom toolbar
        },
      },
    });

    // Set initial content
    // quill.setContents(value);
    quill.clipboard.dangerouslyPasteHTML(value.trim());


    // Handle content change
    quill.on("text-change", () => {
      const content = quill.root.innerHTML;
      onChange(content);
    });

    editorRef.current = quill;

    // Clean up on unmount
    return () => {
      quill.off("text-change");
    };
  }, []);

  return (
    <>
      <style>
        {`
          #custom-toolbar button {
            border: none !important;
            background: none !important;
            cursor: pointer;
            outline: none;
            padding: 0;
            margin: 0;
          }

          .ql-container{
                      border: 1px solid black
}

        `}
      </style>
      <div ref={quillRef} style={{ minHeight: 200 }} />
      <div>
        <div id="custom-toolbar" className="flex gap-2">
          <button type="button" className="ql-bold" />
          <button type="button" className="ql-italic" />
          <button type="button" className="ql-underline" />
          <button type="button" className="ql-strike" />
          <button type="button" className="ql-link" />
          <button type="button" className="ql-list" value="bullet" />
          <button type="button" className="ql-list" value="ordered" />
          <button class="ql-image"></button>
          <button class="ql-video"></button>
          <button class="ql-code-block"></button>
        </div>
      </div>
    </>
  );
};

export default QuillEditor;
