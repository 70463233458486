import * as React from "react";
const TrackingIcon = ({ fill = "#2E2E2E", hoverFill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={fill} />
    <path
      d="M10.2162 8.37368C10.3386 8.37448 10.4619 8.41027 10.5661 8.47566C10.6093 8.40627 10.5937 8.23471 10.6021 8.12973C10.5813 5.64683 7.35275 4.74221 6.04102 6.81199L6.38895 7.00595C6.75887 6.43607 7.40074 6.05814 8.1286 6.05814C9.45653 6.07294 10.2626 7.16612 10.2162 8.37368Z"
      fill={fill}
    />
    <path
      d="M11.5045 6.06083C12.155 5.30398 11.4731 4.11182 10.4903 4.29378C9.97861 4.36637 9.57569 4.85047 9.58449 5.36557C9.56809 6.12901 10.4071 6.6819 11.1002 6.36336C11.1324 6.34937 11.1626 6.33497 11.1928 6.31717C11.5163 6.86486 11.6881 7.48814 11.6881 8.12961C11.6881 10.0912 10.0924 11.6869 8.13078 11.6869C7.5331 11.6869 6.94722 11.5369 6.42753 11.2532C6.45592 11.1718 6.46772 11.0904 6.46912 11.0026C6.46912 10.5827 6.12719 10.2428 5.70727 10.2428C5.29875 10.2336 4.93863 10.5931 4.94742 11.0026C4.93483 11.6373 5.72307 12.0052 6.19817 11.5835C9.76265 13.5323 13.5949 9.54392 11.5045 6.06083Z"
      fill={fill}
    />
    <path
      d="M7.38072 4.64424C8.12157 4.50887 8.9722 4.57225 9.67626 4.92618C9.72965 4.80321 9.80623 4.68803 9.90221 4.59425C9.09437 4.18833 8.13857 4.07235 7.26074 4.26831C7.33753 4.37769 7.38052 4.50727 7.38072 4.64424Z"
      fill={fill}
    />
    <path
      d="M5.05736 10.6071C5.12415 10.4941 5.22593 10.397 5.3393 10.3352C4.8434 9.70731 4.57346 8.93146 4.57346 8.12963C4.57366 7.60493 4.68883 7.09483 4.90139 6.63733L5.8528 7.16702C4.78741 9.82748 8.23912 11.8749 10.0634 9.66852C10.8532 9.83788 11.2027 8.7443 10.4623 8.42517C10.2295 8.32399 9.93678 8.38237 9.75841 8.56314C9.51286 8.79149 9.49487 9.20981 9.71982 9.45816C9.3279 9.92566 8.74642 10.2014 8.12874 10.2014C6.69563 10.2322 5.64784 8.67512 6.20513 7.36338L7.16294 7.89527C7.00817 8.50975 7.49247 9.13282 8.13074 9.12502C9.44608 9.07823 9.44568 7.18402 8.13074 7.13742C7.8238 7.13662 7.51966 7.28639 7.3369 7.53335C7.09595 7.39977 6.26972 6.94146 6.04116 6.81349C5.57506 6.55414 4.76602 6.10643 4.30551 5.84968C4.07576 5.72471 3.8804 6.06804 4.11155 6.19761L4.55086 6.44136C3.90999 7.7547 4.10235 9.51515 5.05736 10.6071Z"
      fill={fill}
    />
    <path
      d="M6.25815 5.10468C6.64687 5.5244 7.39352 5.22106 7.38073 4.64558C7.35193 3.78115 6.09758 3.77675 6.06899 4.64558C6.06899 4.67917 6.07119 4.71256 6.07879 4.74616C5.5189 5.08409 5.06519 5.54799 4.73926 6.08969L5.09119 6.28365C5.37693 5.81034 5.77465 5.40462 6.25815 5.10468Z"
      fill={fill}
    />
    <path
      d="M8.13073 2.80005C5.19132 2.80005 2.7998 5.19157 2.7998 8.13098C3.09275 15.2032 13.1697 15.2012 13.4617 8.13098C13.4617 5.19157 11.0701 2.80005 8.13073 2.80005ZM8.13073 13.062C5.41128 13.062 3.19972 10.8504 3.19972 8.13098C3.46987 1.58929 12.7912 1.59129 13.0617 8.13098C13.0617 10.8504 10.8482 13.062 8.13073 13.062Z"
      fill={fill}
    />
  </svg>
);
export default TrackingIcon;
