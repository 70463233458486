import React, { useCallback } from "react";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/button/button";
import { colors } from "../../../utils/theme";
import { Label } from "./AssessmentListDetails.styles";
import { assessmentCandidateList } from "./dummyData";
import dayjs from "dayjs";

const CandidateCard = ({ item, onClickAssessment }) => {
  return (
    <div
      className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
      onClick={() => onClickAssessment && onClickAssessment(item)}
    >
      <label className="w-1/4 font-medium text-xs text-primary">
        {item.refId}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item.name}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item.status}
        {!item.read && <label className={`text-secondary pl-2`}>New</label>}
      </label>
      <label
        style={{ width: "20%" }}
        className="italic font-medium text-xs text-primary"
      >
        {dayjs(item.date).format("DD-MMM-YY")}
        <label className="font-medium text-xs text-primary pl-2">
          {dayjs(item.date).format("HH:mm")}
        </label>
      </label>
    </div>
  );
};

const AssessmentListDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);

  const onBackPress = useCallback(() => {
    navigate(-1);
  }, [navigate]);


  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button className="flex items-center  self-start" onClick={onBackPress}>
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>
      <div className="flex items-center justify-around border rounded-xl mt-4 overflow-hidden bg-[#FAFAFA] px-6 py-4">
        <div className="flex flex-col flex-1 gap-2">
          <Label>Assessment reference</Label>
          <label className="text-xs font-medium text-primary">
            {state.refId}
          </label>
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <Label>Title</Label>
          <label className="text-xs font-medium text-primary">
            {state.title}
          </label>
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <Label>Status</Label>
          <label className="text-xs font-medium text-primary">
            Assessment completed ({state.assessmentsTaken}){" "}
            <span className="text-[#FF9800]">New (4)</span>
          </label>
        </div>
        <Button
          onClick={() => navigate(`/my-assessments/assessment-view/${state?._id}`)}
          variant="outline"
          color={colors.LightGrey}
          textStyle={{ color: colors.TextPrimary }}
          style={{ borderRadius: 4 }}
        >
          View
        </Button>
      </div>
      <div className="flex flex-1 flex-col pt-6 max-h-fit overflow-y-hidden">
        <div className="flex items-center pb-3 pl-3">
          <label className="w-1/4 flex font-semibold text-xs text-primary">
            Candidate Reference
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Candidate Name
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Status
          </label>
          <div style={{ width: "20%" }} />
        </div>
        <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
          {assessmentCandidateList.map((item) => {
            return <CandidateCard
              key={item.refId}
              item={item}
            />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AssessmentListDetails;
