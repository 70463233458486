import { useState } from "react";
import profile from "../../assets/profilePic1.png";
import About from "./Components/About";
import Experience from "./Components/Experience";
import Skills from "./Components/Skills";
import CameraFilledIcon from "../../assets/svg/CameraFilledIcon";
import Divider from "../../components/Divider/Divider";
import ReferIcon from "../../assets/svg/ReferIcon";
import TrackingIcon from "../../assets/svg/TrackingIcon";
import LikeIcon from "../../assets/svg/LikeIcon";

const tabs = [
  "About",
  "Experience",
  "Skills",
  "Education & Certifications",
  "Message",
];

const RenderBody = ({ activeTab }) => {
  switch (activeTab) {
    case 0:
      return <About />;
    case 1:
      return <Experience />;
    case 2:
      return <Skills />;
    case 3:
      return <div />;
    case 4:
      return <div />;
    default:
      return <div />;
  }
};

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [trackHover, setTrackHover] = useState(false);
  const [likeHover, setLikeHover] = useState(false);

  return (
    <div className="flex bg-white flex-col flex-1 h-auto overflow-hidden w-full shadow-[2px_2px_12px_0px_rgba(180,180,180,0.25)] rounded-lg p-5">
      <div className="flex items-center bg-[#FAFAFA] py-3.5 pb-[18px] pl-3.5 pr-[18px] rounded-xl border border-[#F7F7F7] gap-3 mb-4">
        <div className="relative">
          <img src={profile} className="rounded-full h-[64px] w-[64px]" />
          <div className="absolute bottom-[-3px] right-[-3px]">
            <CameraFilledIcon />
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <div className="flex items-center gap-8">
            <span className="font-montserrat text-primary font-semibold text-base">
              John Smith
            </span>
            <div className="flex items-center gap-2">
              <div className="w-[8px] h-[8px] bg-[#23BD33] rounded-full animate-pulse" />
              <span className="font-montserrat font-bold text-[10px] text-[#23BD33]">
                Available for work
              </span>
            </div>
          </div>
          <Divider className={"bg-[#EFBF04]"} />
          <div className="flex justify-between pr-2.5 relative">
            <span className="font-montserrat text-primary font-medium text-xs max-[900px]:w-min">
              Senior Application Developer
            </span>
            <div className="flex gap-12 absolute top-0 right-2.5">
              <div className="flex items-center gap-2">
                <div className="group flex justify-center items-center py-1.5 px-2.5 border rounded bg-[#ECECEC] hover:bg-[#EFBF04] cursor-pointer gap-2">
                  <ReferIcon className="group-hover:fill-white" />
                  <span className="font-medium text-[10px] text-primary group-hover:text-white">
                    Refer
                  </span>
                </div>
                <span className="font-bold text-[10px] text-primary">345</span>
              </div>
              <div className="flex items-center gap-2">
                <div
                  onMouseEnter={() => setTrackHover(true)}
                  onMouseLeave={() => setTrackHover(false)}
                  className="group flex justify-center items-center py-1.5 px-2.5  rounded bg-[#ECECEC] hover:bg-[#EFBF04] cursor-pointer gap-2"
                >
                  <TrackingIcon fill={trackHover ? "white" : "#2E2E2E"} />
                  <span className="font-medium text-[10px] text-primary group-hover:text-white">
                    Track
                  </span>
                </div>
                <span className="font-bold text-[10px] text-primary">230</span>
              </div>
              <div className="flex items-center gap-2">
                <div
                  className="group flex justify-center items-center py-1.5 px-2.5 rounded bg-[#ECECEC] hover:bg-[#EFBF04] cursor-pointer gap-2"
                  onMouseEnter={() => setLikeHover(true)}
                  onMouseLeave={() => setLikeHover(false)}
                >
                  <LikeIcon fill={likeHover ? "white" : "#2E2E2E"} />
                  <span className="font-medium text-[10px] text-primary group-hover:text-white">
                    Like
                  </span>
                </div>
                <span className="font-bold text-[10px] text-primary">
                  1,123
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-around border-b mt-2">
        {tabs?.map((item, index) => {
          return (
            <button
              onClick={() => setActiveTab(index)}
              style={{
                borderBottom:
                  activeTab === index
                    ? "2px solid #EFBF04"
                    : "2px solid transparent",
              }}
              className="pb-1 px-2.5"
            >
              <span className="font-montserrat text-primary font-medium text-xs">
                {item}
              </span>
            </button>
          );
        })}
      </div>
      <RenderBody activeTab={activeTab} />
    </div>
  );
};

export default Profile;
