import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import CustomSlider from "../../../../../components/CustomSlider/CustomSlider";
import { useState } from "react";
import PlusIcon from "../../../../../assets/svg/PlusIcon";

const sliderValues = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 9.7,
    label: "10",
  },
];

const skillsLastUsed = [
  {
    label: "Current",
    value: "current",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
  {
    label: "2+ years ago",
    value: "2yearsAgo",
  },
];

const selectStyle = {
  MozAppearance: "none",
  WebkitAppearance: "none",
  appearance: "none",
  backgroundImage: `url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2032%2032%22%20stroke-width%3D%220%22%20stroke%3D%22%232e2e2e%22%20class%3D%22custom-arrow%22%3E%3Cpath%20d%3D%22M9.3335%2013.333L16.0002%2019.9997L22.6668%2013.333%22%20stroke%3D%22%232e2e2e%22%20stroke-opacity%3D%221%22%20stroke-width%3D%224%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20/%3E%3C/svg%3E')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right .7em center",
  backgroundSize: "16px auto",
  paddingRight: "2.5em",
};

const RenderFormSkill = ({ skillSection, index }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const handleSave = (data) => {
    console.log("skill payload", data);
  };

  return (
    <form
      key={skillSection.id}
      onSubmit={handleSubmit(handleSave)}
      className="flex gap-4 bg-[#FAFAFA] py-3 pl-3.5 pb-6 pe-6 rounded-xl border justify-between items-center"
    >
      <div className="w-1/2 flex gap-[27px]">
        <div className="flex w-2/5">
          <CustomInput
            label="Skill"
            placeholder="Ex: Python"
            bgColor="bg-white"
            inActiveOutlineColor="border-[#E9EAF0]"
            control={control}
            errors={errors}
            name="skills"
            register={{
              ...register("dob", {
                required: "This field is required.",
              }),
            }}
            labelStyle={`text-xs`}
            className={`border !rounded-[8px]`}
          />
        </div>
        <div className="flex w-2/5">
          <CustomSlider
            label="Skill Rating"
            step={1}
            min={0}
            max={10}
            fillerClass={`h-[5px]`}
            defaultValue={5}
            sliderValues={sliderValues}
            labelStyle={`text-xs`}
          />
        </div>
      </div>
      <div className="w-4/12 flex justify-between">
        <div className="flex flex-col w-1/3">
          <CustomInput
            label="No. of years"
            placeholder="Ex: 6"
            bgColor="bg-white"
            inActiveOutlineColor="border-[#E9EAF0]"
            control={control}
            errors={errors}
            name="numberOfYear"
            register={{
              ...register("dob", {
                required: "This field is required.",
              }),
            }}
            labelStyle={`text-xs`}
            className={`border !rounded-[8px]`}
          />
        </div>
        <div className="w-2/4 flex flex-col">
          <span className="font-montserrat text-light-black font-medium text-xs mb-2">
            Last Used
          </span>
          <Controller
            name={`skills.${index}.lastUsed`}
            control={control}
            rules={{ required: true }}
            defaultValue={skillsLastUsed[0]?.value}
            render={({ field }) => (
              <select
                {...field}
                style={selectStyle}
                className={`w-full h-auto leading-normal rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center justify-between border !rounded-[8px] border-[#E9EAF0] cursor-pointer`}
              >
                <option value="" disabled>
                  Please select
                </option>
                {skillsLastUsed?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
      </div>
      <button className="font-bold text-light-black text-xs pt-5">Save</button>
    </form>
  );
};

const SkillSection = () => {
  const [skills, setSkills] = useState([
    { id: 1, skill: "", experience: "", lastUsed: "" },
  ]);

  const handleAddSkill = () => {
    setSkills([
      ...skills,
      { id: skills.length + 1, skill: "", experience: "", lastUsed: "" },
    ]);
  };

  return (
    <div className="flex flex-col gap-6 border rounded-lg px-4 py-7 mt-5">
      <label className="text-primary font-medium text-sm text-center">
        Please add relevant skills of the candidate
      </label>

      {skills.map((skillSection, index) => (
        <RenderFormSkill
          key={skillSection.id}
          skillSection={skillSection}
          index={index}
        />
      ))}

      <button
        onClick={handleAddSkill}
        className="flex items-center justify-center gap-3 border text-sm text-primary rounded-md px-2 py-1.5 font-semibold w-max"
      >
        <PlusIcon className={"w-4 h-4"} fill={"#2e2e2e"} />
        Add Skill
      </button>
    </div>
  );
};

export default SkillSection;
