import SearchIcon from "../../../assets/svg/SearchIcon";
import AboutSection from "../../ContactsPage/CompaniesSection/AddCompanyPage/components/AboutSection";
import AddContactSection from "../../ContactsPage/CompaniesSection/AddCompanyPage/components/AddContactSection";

const data = [
  {
    id: "AB-1245",
    companyName: "Ashutec Solutions Pvt.",
  },
  {
    id: "AB-3024",
    companyName: "Ashugentect Technologies",
  },
  {
    id: "AB-1267",
    companyName: "Ashupecx Solutions",
  },
  {
    id: "AB-1245",
    companyName: "Ashutec Solutions Pvt.",
  },
  {
    id: "AB-3024",
    companyName: "Ashugentect Technologies",
  },
  {
    id: "AB-1267",
    companyName: "Ashupecx Solutions",
  },
  {
    id: "AB-1245",
    companyName: "Ashutec Solutions Pvt.",
  },
  {
    id: "AB-3024",
    companyName: "Ashugentect Technologies",
  },
  {
    id: "AB-1267",
    companyName: "Ashupecx Solutions",
  },
  {
    id: "AB-1245",
    companyName: "Ashutec Solutions Pvt.",
  },
  {
    id: "AB-3024",
    companyName: "Ashugentect Technologies",
  },
  {
    id: "AB-1267",
    companyName: "Ashupecx Solutions",
  },
  {
    id: "AB-1245",
    companyName: "Ashutec Solutions Pvt.",
  },
  {
    id: "AB-3024",
    companyName: "Ashugentect Technologies",
  },
  {
    id: "AB-1267",
    companyName: "Ashupecx Solutions",
  },
];

const Step1 = ({ setCreateNewCompany, createNewCompany }) => {
  const onCreateNewCompany = () => {
    setCreateNewCompany(true);
  };

  return (
    <div className="flex mt-10 flex-col">
      {createNewCompany ? (
        <>
          <AboutSection />
          <AddContactSection />
        </>
      ) : (
        <>
          <span className="text-primary font-semibold text-sm text-center mb-14">
            Add company to this job requirement
          </span>

          <div className="gap-y-2 flex flex-1 flex-col min-w-[60%] self-center">
            <span
              className="text-[#00BB29] self-end font-medium text-xs px-2 py-1 rounded cursor-pointer"
              onClick={onCreateNewCompany}
            >
              Create new company contact
            </span>
            <div className="flex flex-1 border border-[#dddddd] rounded-lg gap-3 items-center justify-between px-3 ">
              <input
                className="bg-white flex flex-1 text-xs font-medium text-light-black outline-none px-2 py-3 rounded-lg"
                placeholder="Search by Name, Title or Ref number..."
              />
              <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
            </div>
            <div className="flex flex-1 flex-col border border-[#dddddd] rounded-lg gap-y-1 px-2.5 py-2 min-h-40 max-h-40 overflow-y-auto">
              {data?.map((company) => (
                <span className="text-primary font-medium text-xs px-2 py-1 rounded hover:bg-[#f7f7f7] whitespace-pre cursor-pointer">
                  {company?.companyName}
                  {"        "}
                  {company?.id}
                </span>
              ))}
            </div>
            <div className="flex flex-1 border border-[#dddddd] rounded-lg gap-3 items-center justify-between px-3 py-2.5">
              <span className="text-primary font-semibold text-xs text-center ">
                Add company to this job requirement
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step1;
