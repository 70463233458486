import * as React from "react";

const LikeIcon = ({ fill = "#2E2E2E", hoverFill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4958_80)">
      <path
        d="M1.19528 6.64478H3.88213V13.7991H1.19642C1.12188 13.7992 1.04805 13.7847 0.97914 13.7562C0.910234 13.7278 0.847609 13.6861 0.79485 13.6334C0.742092 13.5808 0.700235 13.5182 0.671676 13.4494C0.643118 13.3805 0.628418 13.3067 0.628418 13.2322V7.21163C0.628418 7.06129 0.68814 6.91711 0.794447 6.8108C0.900753 6.7045 1.04494 6.64478 1.19528 6.64478Z"
        stroke={fill}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.89375 13.8321L4.7109 14.2401C5.02747 14.3978 5.37604 14.4789 5.73032 14.4778H13.1692C13.4843 14.4777 13.7884 14.3623 14.0244 14.1535C14.2604 13.9447 14.4118 13.6568 14.4503 13.3441L15.3566 7.4938C15.3842 7.31179 15.3728 7.126 15.3233 6.94868C15.2739 6.77137 15.1874 6.60655 15.0696 6.46509C14.9517 6.32364 14.8053 6.20875 14.6399 6.12801C14.4744 6.04727 14.2937 6.0025 14.1098 5.99666H9.13147V2.69723C9.13005 2.52919 9.09496 2.36313 9.02826 2.20888C8.96156 2.05464 8.86462 1.91533 8.74315 1.79919C8.62169 1.68305 8.47817 1.59245 8.32109 1.53273C8.16401 1.47302 7.99655 1.4454 7.82861 1.45152C7.61504 1.44916 7.40452 1.50226 7.21762 1.60562C7.03073 1.70899 6.87385 1.85908 6.76232 2.04123L3.88232 6.64466V13.7989"
        stroke={fill}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4958_80">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LikeIcon;
