import Divider from "../../../components/Divider/Divider";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { skillsData } from "../dummyData";

const SkillItem = ({ skill, level, experience, lastUsed, showDivider }) => {
  return (
    <>
      <div className="flex items-center my-4">
        <label
          style={{ width: "20%" }}
          className="flex font-medium text-xs text-primary"
        >
          {skill}
        </label>
        <label
          style={{ width: "25%" }}
          className="flex font-medium text-xs text-primary"
        >
          <ProgressBar value={level} total={10} />
        </label>
        <label
          style={{ width: "40%" }}
          className="flex font-medium text-xs text-primary justify-center"
        >
          {experience}
        </label>
        <label
          style={{ width: "15%" }}
          className="flex font-medium text-xs text-primary"
        >
          {lastUsed}
        </label>
      </div>
      {showDivider && <Divider className="bg-[#DDDDDD]" />}
    </>
  );
};

const Skills = () => {
  return (
    <div className="h-full flex flex-1 flex-col pt-[72px] px-[30px] overflow-y-scroll no-scrollbar">
      <div className="flex items-center pb-3">
        <label
          style={{ width: "20%" }}
          className="flex font-bold text-sm text-primary"
        >
          Skill
        </label>
        <label
          style={{ width: "25%" }}
          className="flex font-bold text-sm text-primary"
        >
          Level out of 10
        </label>
        <label
          style={{ width: "40%" }}
          className="flex font-bold text-sm text-primary justify-center"
        >
          Years of experience
        </label>
        <label
          style={{ width: "15%" }}
          className="flex font-bold text-sm text-primary"
        >
          Last used
        </label>
      </div>
      {skillsData.map((skill, index) => (
        <SkillItem
          key={skill.skill}
          skill={skill.skill}
          level={skill.level}
          experience={skill.experience}
          lastUsed={skill.lastUsed}
          showDivider={index < skillsData.length - 1}
        />
      ))}
    </div>
  );
};

export default Skills;
