import * as React from "react";
const ReferIcon = ({ className = "" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4958_391)">
      <path
        d="M6.97916 8.38614C6.97916 7.03689 6.20941 5.86414 5.08603 5.28374C5.69559 4.80767 6.08862 4.06661 6.08862 3.23502C6.08862 1.80189 4.92269 0.635986 3.48959 0.635986C2.0565 0.635986 0.890562 1.80192 0.890562 3.23502C0.890562 4.06661 1.28359 4.80767 1.89316 5.28374C0.76975 5.86414 0 7.03689 0 8.38614V8.85489H6.97916V8.38614ZM0.980656 7.91739C1.20138 6.7333 2.24247 5.83408 3.48959 5.83408C4.73672 5.83408 5.77778 6.73333 5.99853 7.91739H0.980656ZM1.82803 3.23502C1.82803 2.31883 2.57341 1.57349 3.48956 1.57349C4.40572 1.57349 5.15109 2.31886 5.15109 3.23502C5.15109 4.15117 4.40572 4.89655 3.48956 4.89655C2.57341 4.89655 1.82803 4.15121 1.82803 3.23502Z"
        className={`fill-[#2E2E2E] ${className}`}
      />
      <path
        d="M13.2445 10.6799C14.169 10.0752 14.7812 9.03082 14.7812 7.84598C14.7812 5.98001 13.2632 4.46191 11.3972 4.46191C9.53121 4.46191 8.01312 5.98001 8.01312 7.84598C8.01312 9.03082 8.62534 10.0752 9.54993 10.6799C7.92912 11.393 6.79443 13.014 6.79443 14.8953V15.364H16V14.8953C16 13.014 14.8653 11.393 13.2445 10.6799ZM7.76175 14.4265C7.99256 12.626 9.5349 11.23 11.3972 11.23C13.2595 11.23 14.8018 12.626 15.0326 14.4265H7.76175ZM13.8437 7.84598C13.8437 9.19501 12.7462 10.2925 11.3972 10.2925C10.0482 10.2925 8.95062 9.19501 8.95062 7.84598C8.95062 6.49695 10.0482 5.39941 11.3972 5.39941C12.7462 5.39941 13.8437 6.49695 13.8437 7.84598Z"
        className={`fill-[#2E2E2E] ${className}`}
      />
      <path
        d="M3.79037 11.9214L4.38691 12.5179H0.9375V9.82129H0V13.4554H4.38691L3.79037 14.052L4.45328 14.7149L6.1815 12.9867L4.45328 11.2585L3.79037 11.9214Z"
        className={`fill-[#2E2E2E] ${className}`}
      />
    </g>
    <defs>
      <clipPath id="clip0_4958_391">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ReferIcon;
