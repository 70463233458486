import { createBrowserRouter, Navigate, redirect } from "react-router-dom";

import LandingPage from "../pages/LandingPage/LandingPage";

import Layout from "../pages/Layout/Layout";
import LoginPage from "../pages/LoginPage/LoginPage";
import RegisterPage from "../pages/RegisterPage/ResgisterPage";
import DashboardPage from "../pages/Dashboard/Dashboard";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import AddCandidatePage from "../pages/ContactsPage/CandidatesSection/AddCandidatePage/AddCandidatePage";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import MyAssessmentsPage from "../pages/MyAssessmentsPage/MyAssessmentsPage";
import CalendarsPage from "../pages/CalendarsPage/CalendarsPage";

import CreateAssessment from "../pages/MyAssessmentsPage/CreateAssessmentSection/CreateAssessmentSection";
import MyAssessmentsDetailsPage from "../pages/MyAssessmentsDetailsPage/MyAssessmentsDetailsPage";
import CandidateAssessments from "../pages/MyAssessmentsPage/CandidateAssessments/CandidateAssessments";
import AnswerAssessment from "../pages/MyAssessmentsPage/AnswerAssessment/AnswerAssessment";
import Profile from "../pages/ProfilePage/ProfilePage";
import { useSelector } from "react-redux";
import AddCompanyPage from "../pages/ContactsPage/CompaniesSection/AddCompanyPage/AddCompanyPage";
import { useAxiosInstance } from "../utils/setupInterceptor";
import JobPage from "../pages/JobPage/JobPage";
import CreateJobPage from "../pages/CreateJobPage/CreateJobPage";
import CandidateSection from "../pages/ContactsPage/CandidatesSection/CandidatesSection";
import CompaniesSection from "../pages/ContactsPage/CompaniesSection/CompaniesSection";
import AssessmentListDetails from "../pages/MyAssessmentsPage/AssessmentListDetails/AssessmentListDetails";
import AssessmentView from "../pages/MyAssessmentsPage/AssessmentView/AssessmentView";

function useAuthLoader() {
  const auth = useSelector((state) => state.auth);
  const { authenticated, user } = auth;

  const loginLoader = ({ request }) => {
    if (authenticated && user && user._id) {
      return redirect("/");
    }

    return null;
  };

  const authLoader = ({ request }) => {
    // console.log('auth', auth);
    if (authenticated && user && user._id) {
      return null;
    }

    return redirect("/login");
  };

  return {
    loginLoader,
    authLoader,
  };
}

function useRoutes() {
  const auth = useSelector((state) => state.auth);

  const { loginLoader, authLoader } = useAuthLoader();
  useAxiosInstance();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element:
            auth.authenticated && auth.user && auth.user._id ? (
              <Navigate to="/dashboard" />
            ) : (
              <LandingPage />
            ),
        },
        {
          path: "/login",
          loader: loginLoader,
          element: <LoginPage />,
        },
        {
          path: "/email-verification",
          loader: loginLoader,
          element: <EmailVerification />,
        },
        {
          path: "/register",
          loader: loginLoader,
          element: <RegisterPage />,
        },
        {
          path: "/dashboard",
          loader: authLoader,
          element: <DashboardPage />,
        },
        {
          path: "/contacts",
          loader: authLoader,
          children: [
            {
              index: "/",
              element: <ContactsPage />,
              children: [
                {
                  path: "/contacts/candidates",
                  element: <CandidateSection />,
                },
                {
                  path: "/contacts/companies",
                  element: <CompaniesSection />,
                },
              ],
            },
            {
              path: "add-candidate",
              element: <AddCandidatePage />,
            },
            {
              path: "add-company",
              element: <AddCompanyPage />,
            },
            {
              path: "edit-company/:companyId",
              element: <AddCompanyPage />,
            },
          ],
        },
        {
          path: "/my-assessments",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <MyAssessmentsPage />,
            },
            {
              path: "assessment-details/:id",
              element: <MyAssessmentsDetailsPage />,
            },
            {
              path: "create-assessment",
              element: <CreateAssessment />,
            },
            {
              path: "edit-assessment/:assessmentId",
              element: <CreateAssessment />,
            },
            {
              path: "assessment-details",
              element: <AssessmentListDetails />,
            },
            {
              path: "assessment-view/:assessmentId",
              element: <AssessmentView />,
            },
            {
              path: "candidate-Assessments",
              element: <CandidateAssessments />,
            },
            {
              path: "candidate-Assessments/:id",
              element: <AnswerAssessment />,
            },
          ],
        },
        {
          path: "/calendar",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <CalendarsPage />,
            },
          ],
        },
        {
          path: "/job",
          loader: authLoader,
          children: [
            {
              index: true,
              element: <JobPage />,
            },
            {
              path: "create",
              loader: authLoader,
              element: <CreateJobPage />,
            },
          ],
        },
        {
          path: "/my-profile",
          loader: authLoader,
          element: <Profile />,
        },
        {
          path: "*",
          loader: authLoader,
          element: <DashboardPage />,
        },
      ],
    },
  ]);

  return {
    router,
  };
}

export default useRoutes;
