import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

/**
 * Extracts the dial code (country calling code) from a phone number.
 * 
 * @param phoneNumber - The phone number to extract the dial code from.
 * @param countryCode - The country code (optional) to help parse the number.
 * @returns The extracted dial code, or null if the phone number is invalid.
 */
const extractDialCode = (phoneNumber, countryCode = '') => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    const dialCode = `+${parsedPhoneNumber?.countryCallingCode}`; // Get the dial code with '+'
    const localNumber = parsedPhoneNumber?.nationalNumber; // Get the local part of the number

console.log('====================================');
console.log(dialCode);
console.log('====================================',localNumber);
    return {
        dialCode,
        localNumber
    }; if (parsedPhoneNumber?.isValid()) {
    }

    return null; // Return null if the phone number is invalid
};


function formatPhoneNumber(phoneNumber) {
    // Parse the phone number with the provided country code (e.g., 'GB' for UK)
    const phone = parsePhoneNumberFromString(phoneNumber.toString());

    // Check if the phone number is valid
    if (!phone) {
        return "Invalid phone number.";
    }

    // Format the phone number in international format
    return phone.formatInternational();
}

export {
    extractDialCode,
    formatPhoneNumber
}