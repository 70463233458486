import * as React from "react";
const ClockIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 3.75C13.4783 3.75 11.0132 4.49777 8.91648 5.89876C6.81976 7.29975 5.18556 9.29103 4.22054 11.6208C3.25552 13.9505 3.00303 16.5141 3.49499 18.9874C3.98696 21.4607 5.20127 23.7325 6.98439 25.5156C8.76751 27.2987 11.0393 28.5131 13.5126 29.005C15.9859 29.497 18.5495 29.2445 20.8792 28.2795C23.209 27.3144 25.2003 25.6802 26.6012 23.5835C28.0022 21.4868 28.75 19.0217 28.75 16.5C28.746 13.1197 27.4015 9.879 25.0112 7.48877C22.621 5.09854 19.3803 3.75397 16 3.75ZM16 27.75C13.775 27.75 11.5999 27.0902 9.74984 25.854C7.89979 24.6179 6.45785 22.8609 5.60636 20.8052C4.75488 18.7495 4.53209 16.4875 4.96617 14.3052C5.40025 12.1229 6.47171 10.1184 8.04505 8.54505C9.6184 6.97171 11.623 5.90025 13.8052 5.46617C15.9875 5.03208 18.2495 5.25487 20.3052 6.10636C22.3609 6.95784 24.1179 8.39978 25.354 10.2498C26.5902 12.0999 27.25 14.275 27.25 16.5C27.2467 19.4827 26.0604 22.3422 23.9513 24.4513C21.8422 26.5604 18.9827 27.7467 16 27.75ZM23.75 16.5C23.75 16.6989 23.671 16.8897 23.5303 17.0303C23.3897 17.171 23.1989 17.25 23 17.25H16C15.8011 17.25 15.6103 17.171 15.4697 17.0303C15.329 16.8897 15.25 16.6989 15.25 16.5V9.5C15.25 9.30109 15.329 9.11032 15.4697 8.96967C15.6103 8.82902 15.8011 8.75 16 8.75C16.1989 8.75 16.3897 8.82902 16.5303 8.96967C16.671 9.11032 16.75 9.30109 16.75 9.5V15.75H23C23.1989 15.75 23.3897 15.829 23.5303 15.9697C23.671 16.1103 23.75 16.3011 23.75 16.5Z"
      fill="#6F6F6F"
    />
  </svg>
);
export default ClockIcon;
